export default {
    props: ['details', 'baseUrl'],
    emits: ['signature-html'],
    data() {
        return {
            'html': '',
            'styleRef': ''
        }
    },
    updated() {
        this.getTemplateHtml();
    },
    methods: {
        getIconUri(icon) {
            const subdir = (this.details.socialIconColour === 'platform') ? 'platform-colour/' : '';
            return this.baseUrl + '/social-icons/' + subdir + icon + '.png';
        },
        getTemplateHtml() {
            if (typeof (this.$refs[this.styleRef]) !== 'undefined') {
                let html = this.$refs[this.styleRef].outerHTML;
                html = html.replace(/<!--[\s\S]*?-->/g, '').replace(/(data-.+?=".*?")|(data-.+?='.*?')|(data-[a-zA-Z0-9-]+)/g, '');
                this.$emit('signature-html', {'styleRef': this.styleRef, 'html': html});
            }
        },
        getLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                return 'https://' + url;
            }
            return url;
        },
        nl2br(str, isXhtml) {
            if (typeof str === 'undefined' || str === null) {
                return ''
            }
            const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
            return (str + '')
                .replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
        }
    },
    computed: {
        getLogoUri() {
            const logoUri = (this.details.logoUri.includes('?a='))
                ? this.details.logoUri + (Math.floor(Math.random() * 10000) + 1)
                : this.details.logoUri + '?a=' + (Math.floor(Math.random() * 10000) + 1);

            //console.log(this.details.logoUri, logoUri);

            return this.baseUrl + logoUri;
        },
        getBannerUri() {
            const bannerUri = (this.details.bannerUri.includes('?a='))
                ? this.details.bannerUri + (Math.floor(Math.random() * 10000) + 1)
                : this.details.bannerUri + '?a=' + (Math.floor(Math.random() * 10000) + 1);
            //console.log(this.details.bannerUri, bannerUri);
            return this.baseUrl + bannerUri;
        },
        textColour() {
            return (this.details.brandColourText !== '') ? this.details.brandColourText : '#222';
        },

        nameColour() {
            return (this.details.nameColour !== '') ? this.details.nameColour : '#222';
        },
        jobTitleColour() {
            return (this.details.jobTitleColour !== '') ? this.details.jobTitleColour : '#222';
        },

    }
}
