<template>
  <button @click="toggleDesign"
          class="btn btn-sm btn-secondary" style=" position:fixed; z-index:10001; right:5px; left:auto; top:5px;" type="button">
    Toggle Design
  </button>
  <div class="row">
    <div :class="{'col-md-6': showDesign, 'col-12': !showDesign}">
      <h2>Companies</h2>
      <div class="row mb-3">
        <div class="col-sm-6" v-if="hasCompanies">
          <label for="existing-company-name" class="form-label">Company</label>
          <select name="existing-company-name" id="existing-company-name" class="form-select" v-model="selectedCompany" v-on:change="updateForm()">
            <option value="">Add New Company...</option>
            <option v-for="company in companies" :key="company" :value="company">{{ getCompanyNameFormatted(company) }}</option>
          </select>
        </div>
        <div class="col-sm-6">
          <label for="company-name" class="form-label">Company Name</label>
          <input type="text" id="company-name" class="form-control" v-model="details.companyName" required>
        </div>
      </div>
      <template v-if="hasCompanyName">
        <div class="card mb-3">
          <div class="card-header">
            Company Details
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <h6>Logo</h6>
                <div class="input-group">
                  <input type="file" id="company_logo" ref="company_logo" class="form-control" v-on:change="handleLogoUpload()" required/>
                  <button v-on:click.prevent="submitLogo()" class="btn btn-secondary" type="button">Upload</button>
                </div>
              </div>
              <template v-if="hasLogo">
                <div class="col-sm-12 mt-3">
                  <h6>Name/Job Title Colours</h6>
                </div>
                <div class="col-sm-6">
                  <label for="name-colour" class="form-label text-nowrap">Name Colour</label>
                  <input type="color" id="name-colour" class="form-control" v-model="details.nameColour">
                </div>
                <div class="col-sm-6">
                  <label for="job-title-colour" class="form-label text-nowrap">Job Title Colour</label>
                  <input type="color" id="job-title-colour" class="form-control" v-model="details.jobTitleColour">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Website</h6>
                </div>
                <div class="col-sm-6">
                  <label for="website__label" class="form-label">Label</label>
                  <input type="text" id="website__label" class="form-control" v-model.lazy="details.website.label">
                </div>
                <div class="col-sm-6">
                  <label for="website__url" class="form-label">URL</label>
                  <input type="text" id="website__url" class="form-control" v-model.lazy="details.website.url">
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <label for="link_logo" class="form-check-label">Link logo?</label>
                    <input type="checkbox" id="link_logo" class="form-check-input" v-model="details.website.linkLogo">
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Social Media</h6>
                </div>
                <div class="col-sm-6">
                  <label for="facebook" class="form-label">Facebook</label>
                  <input type="text" id="facebook" class="form-control" v-model.lazy="details.facebook">
                </div>
                <div class="col-sm-6">
                  <label for="linkedin" class="form-label">LinkedIn</label>
                  <input type="text" id="linkedin" class="form-control" v-model.lazy="details.linkedin">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="instagram" class="form-label">Instagram</label>
                  <input type="text" id="instagram" class="form-control" v-model.lazy="details.instagram">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="twitter" class="form-label">Twitter</label>
                  <input type="text" id="twitter" class="form-control" v-model.lazy="details.twitter">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="youtube" class="form-label">YouTube</label>
                  <input type="text" id="youtube" class="form-control" v-model.lazy="details.youtube">
                </div>
                <div class="col-12 mt-3">
                  <label for="social-icon-color" class="form-label">Social Icon Colour</label><br/>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="socialIconDefault" id="socialIconDefault" value="default"
                           v-model="details.socialIconColour"/>
                    <label class="form-check-label" for="socialIconDefault">Default</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="socialIconPlatform" id="socialIconPlatform" value="platform"
                           v-model="details.socialIconColour"/>
                    <label class="form-check-label" for="socialIconPlatform">Platform correct</label>
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Extra Link</h6>
                </div>
                <div class="col-sm-6">
                  <label for="extra-link-label" class="form-label">Label</label>
                  <input type="text" id="extra-link-label" class="form-control" v-model.lazy="details.extraLink.label">
                </div>
                <div class="col-sm-6">
                  <label for="extra-link-url" class="form-label">URL</label>
                  <input type="text" id="extra-link-url" class="form-control" v-model.lazy="details.extraLink.link">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Extra Text</h6>
                </div>
                <div class="col-sm-12">
                  <label for="extra-text" class="form-label">Extra Text/HTML</label>
                  <textarea type="text" id="extra-text" class="form-control" v-model.lazy="details.extraText"></textarea>
                  <div class="row">
                    <div class="col-auto">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="extraTextAbove" id="extraTextAbove" value="above"
                               v-model="details.extraTextPosition"/>
                        <label class="form-check-label" for="extraTextAbove">Above</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="extraTextBelow" id="extraTextBelow" value="below"
                               v-model="details.extraTextPosition"/>
                        <label class="form-check-label" for="extraTextBelow">Below</label>
                      </div>
                    </div>
                    <div class="col-auto">social icons</div>
                  </div>
                </div>

                <div class="col-sm-12 mt-3">
                  <h6>Call To Action</h6>
                </div>
                <div class="col-sm-5">
                  <div class="form-check">
                    <label for="show-banner" class="form-check-label">Show Call To Action</label>
                    <input type="checkbox" id="show-banner" class="form-check-input" v-model="details.showBanner">
                  </div>
                </div>
                <div class="col-sm-3">
                  <label for="banner-max-width" class="form-check-label">Max width</label>
                  <div class="input-group">
                    <input type="number" id="banner-max-width" class="form-control"
                           v-model="details.bannerMaxWidth">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <label for="banner" class="form-label">Call to Action Image</label>
                  <div class="input-group">
                    <input type="file" id="company_banner" ref="company_banner" class="form-control" v-on:change="handleBannerUpload()" required/>
                    <button v-on:click.prevent="submitBanner()" class="btn btn-secondary">Upload</button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <label for="banner-link" class="form-label">URL</label>
                  <input type="text" id="banner-link" class="form-control" v-model.lazy="details.bannerLink">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Branding</h6>
                </div>
                <div class="col-sm-4 mt-3">
                  <label for="brand-colour" class="form-label">Brand Colour</label>
                  <input type="color" id="brand-colour" class="form-control" v-model="details.brandColour">
                </div>
                <div class="col-sm-4 mt-3">
                  <label for="brand-colour-text" class="form-label">Text Brand Colour</label>
                  <input type="color" id="brand-colour-text" class="form-control" v-model="details.brandColourText">
                </div>
                <div class="col-sm-4 mt-3">
                  <label for="brand-colour-line-width" class="form-label">Line Width</label>
                  <div class="input-group">
                    <input type="number" id="brand-colour-line-width" class="form-control"
                           v-model="details.brandColourLineWidth">
                    <span class="input-group-text">px</span>
                  </div>
                </div>

                <div class="col-sm-12 mt-3">
                  <h6>Legal Disclaimer</h6>
                </div>
                <div class="col-sm-12">
                  <label for="legal-disclaimer" class="form-label">Legal Disclaimer</label>
                  <textarea type="text" id="legal-disclaimer" class="form-control" v-model.lazy="details.legalDisclaimer"></textarea>
                </div>
                <div class="col-sm-3 mt-3">
                  <label for="legal-disclaimer-font-size" class="form-label">Font Size</label>
                  <div class="input-group">
                    <input type="number" id="legal-disclaimer-font-size" class="form-control"
                           v-model.lazy="details.legalDisclaimerFontSize">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
                <div class="col-sm-4 mt-3">
                  <label for="legal-disclaimer-font-color" class="form-label">Text Color</label>
                  <input type="color" id="legal-disclaimer-font-color" class="form-control" v-model="details.legalDisclaimerFontColor">
                </div>


              </template>

            </div>
            <button type="button" class="btn btn-primary text-light sticky-bottom align-self-end mt-1"
                    v-on:click.prevent="saveCompany()">Save
            </button>
          </div>
        </div>
      </template>
    </div>
    <div class="col-md-6" v-show="showDesign">
      <div class="bg-light pt-3 pb-3 ps-3 pe-3 show sticky-top">
        <signature-preview
            :details="details"
            :baseUrl="$parent.baseUrl"
        ></signature-preview>
      </div>
    </div>
  </div>
</template>

<script>
import _clonedeep from "lodash/cloneDeep";
import shared from "@/mixins/api/shared";
import company from "@/mixins/api/company";
import SignaturePreview from "@/components/SignaturePreview";

export default {
  name: "Companies",
  components: {SignaturePreview},
  mixins: [shared, company],
  data() {
    return {
      model: 'company',
      selectedCompany: '',
      showDesign: true,
      logo: '',
      banner: '',
      defaults: {},
      companies: [],
      details: {
        name: '',
        nameColour: '',
        jobTitle: '',
        jobTitleColour: '',
        companyName: '',
        email: {label: 'Email', address: ''},
        phone1: {label: 'Office', number: ''},
        phone2: {label: 'Mobile', number: ''},

        website: {label: 'Website', url: '', linkLogo: true},
        facebook: '',
        linkedin: '',
        instagram: '',
        twitter: '',
        youtube: '',
        socialIconColour: 'default',
        extraLink: {
          label: '',
          link: ''
        },
        extraText: '',
        extraTextPosition: 'below',
        brandColour: '',
        brandColourLineWidth: '3',
        brandColourText: '',
        legalDisclaimer: '',
        legalDisclaimerFontSize: '11',
        legalDisclaimerFontColor: '#333333',
        logoUri: '',
        showBanner: false,
        bannerMaxWidth: '600',
        bannerUri: '',
        bannerLink: '',
      }
    }
  },
  created() {
    this.saveDefault();
    this.getCompanies()
  },
  methods: {
    updateForm() {
      if (this.selectedCompany === '') {
        this.details = {...this.defaults};
      } else {
        this.getCompanySettings();
      }
    },
    setPersonalDetailsToEmpty() {
      console.log(this.details);
      this.details.name = '';
      this.details.jobTitle = '';
      this.details.email = {label: '', address: ''};
      this.details.phone1 = {label: '', number: ''};
      this.details.phone2 = {label: '', number: ''};
      console.log(this.details);
    },
    resetDetails() {
      this.details = {...this.defaults};

      this.details = _clonedeep(this.defaults);
    },
    getTemplateHtml(styleRef) {
      const theTemplate = this.templates.find(template => template.styleRef === styleRef);
      //console.log(theTemplate.styleRef + ': ' + theTemplate.html);
      return theTemplate.html;
    },
    getCompanyNameFormatted(companyName) {
      return companyName.replace(/[-_]/g, ' ');
    },
    saveDefault() {
      this.defaults = {...this.details}
    },
    toggleDesign() {
      this.showDesign = !this.showDesign;
    },
    handleLogoUpload() {
      this.logo = this.$refs.company_logo.files[0];
    },
    handleBannerUpload() {
      this.banner = this.$refs.company_banner.files[0];
    },
  },
  computed: {
    hasCompanyName() {
      return (this.details.companyName !== '');
    },
    hasLogo() {
      return (this.details.logoUri !== '');
    },
    hasCompanies() {
      return (Object.keys(this.companies).length > 0);
    },
  }
}
</script>

<style scoped>

</style>
